<template lang="pug">
    .post-feed
        .post-feed__cover
            img.avatar(:src="photo ? photo : getDefaultImage('profile')" @error="getErrorImage($event, 'profile')" @click="openAccount(socmed, username)")
            .label-socmed
                template(v-if="socmed === 'facebook'")
                    img(src="/images/socmed/fb.png", alt="Facebook")
                template(v-else-if="socmed === 'twitter'")
                    img(src="/images/socmed/tw.png", alt="Twitter")
                template(v-else-if="socmed === 'instagram'")
                    img(src="/images/socmed/ig.png", alt="Instagram")
                template(v-else-if="socmed === 'youtube'")
                    img(src="/images/socmed/yt.png", alt="YouTube")

        .post-feed__content
            .flex.items-center.justify-between.mb-1
                .flex-grow(@click="openAccount(socmed, username)")
                    span.mr-1.uname {{ username }}
                    span.text-gray-500 {{ date }}
                a(:href="url" target="_blank").link Kunjungi sumber Feed

            p(@click="openAccount(socmed, username)") {{ content }}


</template>

<script>
export default {
    name: 'postTweet',
    props: {
        photo: {
            type: String,
        },
        username: {
            type: String,
        },
        date: {
            type: String,
        },
        content: {
            type: String,
        },
        url: {
            type: String,
        },
        socmed: {
            type: String,
        },
        openDetail: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        openAccount(socmed, username) {
            if (this.openDetail) {
                Event.$emit('componentsOpenAccount', socmed, username);
            }
        },
    },
};
</script>

<style lang="sass">
.post-feed
    @apply px-4 py-2 transition-all block w-full text-left text-xs flex
    &__cover
        @apply mr-2 relative
        flex: 0 0 32px
        .label-socmed
            @apply absolute
            width: 20px
            height: 20px
            background-color: #fff
            border-radius: 100%
            top: 23px
            left: -6px
            img
                width: 20px
    &__content
        @apply w-full flex-grow

    .link
        opacity: 0
        font-weight: normal
        font-size: 10px

    &:hover
        .uname
            color: var(--color-primary-500)
        .link
            opacity: 1

</style>
